import { useEffect, useState } from 'react';
import { Textarea } from 'src/components/shad-base/textarea';
import { Input as BaseInput } from 'src/components/shad-base/input';
import { cn } from '@/lib/utils';

export default function Input({
  value,
  onChange,
  placeholder,
  className,
  multiline,
  charLimit = null,
  size = 'default',
  prefixText = null,
  fitContent = false,
  backgroundColor = ' bg-background ',
  ...props
}: {
  value: string;
  onChange: (e) => void;
  placeholder?: string;
  className?: string;
  charLimit?: number;
  multiline?: boolean;
  size?: 'default' | 'sm';
  prefixText?: string;
  fitContent?: boolean;
  backgroundColor?: string;
  [key: string]: unknown;
}) {
  const [charLimitReached, setCharLimitReached] = useState(false);

  useEffect(() => {
    if (charLimit && value?.length > charLimit) {
      setCharLimitReached(true);
    } else {
      setCharLimitReached(false);
    }
  }, [value]);

  const finalCn =
    backgroundColor +
    ' ' +
    'shadow-none' +
    ' ' +
    className +
    ' ' +
    (charLimitReached ? ' border-destructive ' : '') +
    (size === 'sm' && ' h-small-button body2 ');

  const inputProps = {
    value,
    onChange,
    placeholder,
    startAdornment: prefixText ? (
      <p className={cn('text-nowrap', size === 'sm' && 'body2')}>
        {prefixText}
      </p>
    ) : undefined,
    className: finalCn,
    charLimit,
    multiline,
    fitContent,
    ...props
  };

  return (
    <div className="flex w-full flex-col flex-nowrap">
      {multiline ? (
        <Textarea {...inputProps} />
      ) : (
        <BaseInput {...inputProps} />
      )}
      {charLimitReached && (
        <p className="caption mt-sm text-destructive">
          Character limit reached (max: {charLimit})
        </p>
      )}
    </div>
  );
}
