import { Search } from 'lucide-react';
import Input from 'src/components/core/atoms/Input';

export default function CommandInput({
  searchValue,
  setSearchValue,
  placeholder = 'Search...',
  size = 'sm',
  disabled = false
}: {
  searchValue: string;
  setSearchValue: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  size?: 'sm' | 'default';
}) {
  return (
    <div className="flex flex-nowrap items-center bg-popover">
      <Search className="ml-sm h-4 w-4" />
      <Input
        value={searchValue}
        disabled={disabled}
        onChange={(e) => setSearchValue(e.target.value)}
        className={
          'border-0 bg-popover shadow-none ' +
          (size === 'sm' ? 'body2 h-small-button' : '')
        }
        placeholder={placeholder}
      />
    </div>
  );
}
