import { cn } from '@/lib/utils';
import { Check, ChevronsUpDown, X } from 'lucide-react';
import { Fragment, useEffect, useState } from 'react';
import CommandInput from 'src/components/core/atoms/CommandInput';
import Skeleton from 'src/components/core/atoms/Skeleton';
import { Button } from 'src/components/shad-base/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList
} from 'src/components/shad-base/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from 'src/components/shad-base/popover';
import { getListItemKey } from 'src/utils/format';

export type SingleSelectProps = {
  value: string | null;
  setValue: (value: string | null) => void;
  options: { key: string; label: string; icon?: React.ReactNode }[];
  loading?: boolean;
  prefixText?: string;
  placeholder?: string;
  commandEmptyText?: string;
  searchText?: string;
  renderOption?: (option: {
    key: string;
    label: string;
  }) => React.ReactNode;
  disableClear?: boolean;
  disabled?: boolean;
  renderCustomTrigger?: () => React.ReactNode;
  groups?: { key: string; label: string; childrenKeys: string[] }[];
  size?: 'sm' | 'default' | 'xl' | 'lg' | 'icon';
  required?: boolean;
};
export default function SingleSelect({
  value,
  setValue,
  options,
  loading,
  prefixText = null,
  placeholder = 'Select...',
  groups = null,
  commandEmptyText = 'No items found.',
  searchText = 'Search...',
  renderOption = null,
  disableClear = false,
  disabled,
  renderCustomTrigger = null,
  size = 'sm',
  required = false
}: SingleSelectProps) {
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  // Replace any superscript numbers with normal numbers
  const replaceSupers = (str) => {
    if (!str) return;
    const superMap = {
      '⁰': '0',
      '¹': '1',
      '²': '2',
      '³': '3',
      '⁴': '4',
      '⁵': '5',
      '⁶': '6',
      '⁷': '7',
      '⁸': '8',
      '⁹': '9'
    };

    return str.replace(/[⁰¹²³⁴⁵⁶⁷⁸⁹]/g, (match) => {
      return superMap[match];
    });
  };
  // Filter options by search value
  const filteredOptions = options?.filter((option) => {
    const formattedOption = replaceSupers(option.label);
    return formattedOption
      ?.toLowerCase()
      .includes(searchValue.toLowerCase());
  });

  // Remove groups that have no items that match the search value
  const filteredGroups = groups?.reduce(
    (groups, group) => {
      const childrenKeys = group?.childrenKeys?.filter((key) => {
        const option = options.find((option) => option.key === key);
        const formattedOption = replaceSupers(option.label);

        return formattedOption
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });

      if (childrenKeys.length > 0) {
        groups.push({
          ...group,
          childrenKeys
        });
      }
      return groups;
    },
    [] as { key: string; label: string; childrenKeys: string[] }[]
  );

  useEffect(() => {
    if (!open) {
      setSearchValue('');
    }
  }, [open]);

  const selectedOption = options?.find(
    (option) => option.key === value
  );

  return loading ? (
    <Skeleton height={25} />
  ) : (
    <>
      <Popover open={open} onOpenChange={setOpen} modal={true}>
        <PopoverTrigger asChild>
          {renderCustomTrigger ? (
            renderCustomTrigger()
          ) : (
            <Button
              variant="outline"
              size={size}
              role="combobox"
              aria-expanded={open}
              disabled={disabled}
              className="relative w-full min-w-[200px] justify-start"
            >
              <div className="flex flex-nowrap items-center">
                {required && (
                  <p className="body1 text-destructive">*</p>
                )}
                <p className={size === 'sm' ? 'body2' : 'body1'}>
                  &nbsp;
                  {prefixText}
                  &nbsp;
                </p>
                {selectedOption?.icon && (
                  <div className="mr-sm">{selectedOption.icon}</div>
                )}
                <p
                  className={
                    'mr-xl ' +
                    (size === 'sm' ? 'body2 ' : 'body1 ') +
                    (value ? 'text-primary' : 'text-muted')
                  }
                >
                  {selectedOption
                    ? selectedOption.label
                    : placeholder}
                </p>
                {value && !disableClear ? (
                  <X
                    className="absolute right-4 mr-sm h-3 w-3"
                    onClick={(e) => {
                      e.stopPropagation();
                      setValue(null);
                    }}
                  />
                ) : null}
                <ChevronsUpDown className="absolute right-2 ml-2 h-3 w-3 opacity-50" />
              </div>
            </Button>
          )}
        </PopoverTrigger>
        <PopoverContent className="min-w-[200px] p-0">
          <Command loop>
            <CommandList>
              {/* Add Searchbar if more than 5 options */}
              {options?.length > 5 && (
                <div className="border-b">
                  <CommandInput
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    placeholder={searchText}
                    size={size === 'sm' ? 'sm' : 'default'}
                  />
                </div>
              )}
              <CommandEmpty className="flex items-center px-4 pt-2">
                <p className="body2">{commandEmptyText}</p>
              </CommandEmpty>
              <CommandGroup>
                {groups?.length > 0
                  ? filteredGroups?.map((group, idx) => (
                      <Fragment key={getListItemKey(idx)}>
                        <div
                          className={
                            'mb-sm pl-sm ' +
                            (idx === 0 ? 'mt-sm' : 'mt-md')
                          }
                        >
                          <p
                            className={
                              'font-bold ' +
                              (size === 'sm' ? 'body2' : '')
                            }
                          >
                            {group.label}
                          </p>
                        </div>
                        {group.childrenKeys?.map((key) => {
                          const option = options.find(
                            (option) => option.key === key
                          );
                          return (
                            <CommandItem
                              key={option.key}
                              value={option.key}
                              onSelect={(newValue) => {
                                const option = options.find(
                                  (option) => option.key === newValue
                                );

                                setValue(
                                  option.key === value &&
                                    !disableClear
                                    ? null
                                    : option.key
                                );
                                setOpen(false);
                              }}
                            >
                              <div className="flex w-full items-center justify-between">
                                {renderOption ? (
                                  renderOption(option)
                                ) : (
                                  <div className="flex flex-nowrap items-center">
                                    {option.icon && (
                                      <div className="mr-sm">
                                        {option.icon}
                                      </div>
                                    )}
                                    <p
                                      className={
                                        size === 'sm' ? 'body2' : ''
                                      }
                                    >
                                      {option.label}
                                    </p>
                                  </div>
                                )}
                                <Check
                                  className={cn(
                                    'ml-2 h-4 w-4',
                                    value === option.key
                                      ? 'opacity-100'
                                      : 'opacity-0'
                                  )}
                                />
                              </div>
                            </CommandItem>
                          );
                        })}
                      </Fragment>
                    ))
                  : filteredOptions?.map((option) => (
                      <CommandItem
                        key={option.key}
                        value={option.key}
                        onSelect={(newValue) => {
                          const option = options.find(
                            (option) => option.key === newValue
                          );

                          setValue(
                            option.key === value && !disableClear
                              ? null
                              : option.key
                          );
                          setOpen(false);
                        }}
                      >
                        <div className="flex w-full items-center justify-between">
                          {renderOption ? (
                            renderOption(option)
                          ) : (
                            <div className="flex flex-nowrap items-center">
                              {option.icon && (
                                <div className="mr-sm">
                                  {option.icon}
                                </div>
                              )}
                              <p
                                className={
                                  size === 'sm' ? 'body2' : 'body1'
                                }
                              >
                                {option.label}
                              </p>
                            </div>
                          )}
                          <Check
                            className={cn(
                              'ml-2 h-4 w-4',
                              value === option.key
                                ? 'opacity-100'
                                : 'opacity-0'
                            )}
                          />
                        </div>
                      </CommandItem>
                    ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </>
  );
}
